// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html[data-v-7ba5bd90],
body[data-v-7ba5bd90] {
  min-height: 100%;
  height: 100%;
}
.content[data-v-7ba5bd90] {
  padding-left: 0.7rem;
  padding-right: 0.75rem;
  transition-property: margin-left;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.2s;
}
.loading-img[data-v-7ba5bd90] {
  width: 100px;
  height: 100px;
}
.loading-text[data-v-7ba5bd90] {
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 0.5px;
  text-align: center;
  color: #8d8d8d;
}
.loading-subtext[data-v-7ba5bd90] {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: 0.5px;
  text-align: center;
  color: #8d8d8d;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
