// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Style */
.v-popper__popper {
  z-index: 100000 !important;
}
.v-popper--theme-nonius-tooltip .v-popper__inner {
  background: #ededed !important;
  color: #000000 !important;
  padding: 1px 12px !important;
  border: 1.5px solid lightgray;
  box-shadow: 0 4px 11px 0 rgba(0, 0, 0, 0.13) !important;
}
.v-popper--theme-nonius-tooltip .v-popper__inner div {
  display: flex;
  text-align: center;
  align-items: center;
  gap: 5px;
}
.v-popper--theme-nonius-tooltip .v-popper__arrow-outer {
  border-color: lightgray !important;
}
.v-popper--theme-nonius-tooltip .v-popper__arrow-inner {
  visibility: visible;
  border-color: #ededed;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
